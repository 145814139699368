div.splash-screen {
  backdrop-filter: blur(15px);
  background-color: #fff;
  height: 100%;
  position: fixed;
  text-align: center;
  top: 0px;
  width: 100%;
  z-index: 4;
}

div.splash-screen>* {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

div.splash-screen div.logo {
  align-items: center;
  display: flex;
  font-size: 2.5rem;
  justify-content: center;
}

div.splash-screen div.logo>* {
  margin: 5px;
}

div.splash-screen div.logo div.img-container>img {
  filter: grayscale();
  opacity: 0.7;
}

div.splash-screen div.logo div.title-container>span {
  color: #000000a6;
  display: inline-block;
}

div.splash-screen>div {
  opacity: 0;
  transition: 0.5s;
}

div.splash-screen div.logo div.img-container {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100px;
}

div.splash-screen div.logo div.img-container>img {
  height: 90px;
  width: 90px;
}

div.splash-screen div.info>div {
  margin: 10px 0px;
}

div.splash-screen.show div.info {
  opacity: 0.7;
}

div.splash-screen.show div.logo {
  opacity: 1;
}

div.splash-screen.keep {
  animation: splash-screen-build 0.5s ease-in-out 0s 1 normal forwards;
}

div.splash-screen.keep>div:not(.info) {
  opacity: 1;
}

div.splash-screen.keep div.logo div.img-container>img {
  animation: img-keep 1s ease-in-out 0s 1 normal forwards;
}

div.splash-screen.keep div.logo div.title-container>span {
  animation-direction: normal;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: letter-keep;
  animation-timing-function: ease-in-out;
}

div.splash-screen.keep div.logo div.title-container>span#c {
  animation-delay: 1s;
}

div.splash-screen.keep div.logo div.title-container>span#u {
  animation-delay: 1.05s;
}

div.splash-screen.keep div.logo div.title-container>span#n {
  animation-delay: 1.1s;
}

div.splash-screen.keep div.logo div.title-container>span#t1 {
  animation-delay: 1.15s;
}

div.splash-screen.keep div.logo div.title-container>span#r {
  animation-delay: 1.2s;
}

div.splash-screen.keep div.logo div.title-container>span#a {
  animation-delay: 1.25s;
}

div.splash-screen.keep div.logo div.title-container>span#t2 {
  animation-delay: 1.3s;
}

div.splash-screen[hide] {
  animation: splash-screen-hide 0.5s ease-in-out 0s 1 normal forwards;
  animation-fill-mode: forwards;
  display: block !important;
}

div.splash-screen div.splash-info[hide] {
  display: block !important;
  opacity: 0;
  transition: 0.3s;
}

@keyframes letter-keep {
  50% {
    transform: translateY(-10px);
  }

  90% {
    transform: translateY(2px);
  }

  100% {
    color: #8728e0d7;
    transform: none;
  }
}

@keyframes img-keep {
  35% {
    height: 40px;
    rotate: -10deg;
    width: 40px;
  }

  75% {
    height: 115px;
    width: 115px;
  }

  85% {
    filter: none;
    height: 95px;
    rotate: 365deg;
    width: 100px;
  }

  92% {
    height: 108px;
    rotate: 360deg;
    width: 105px;
  }

  100% {
    filter: none;
    height: 100px;
    rotate: 360deg;
    width: 100px;
  }
}

@keyframes splash-screen-build {
  0% {
    background-color: #fff;
  }

  100% {
    background: #ffffffc2;
  }
}

@keyframes splash-screen-hide {
  0% {
    background: #ffffffc2;
  }

  100% {
    background: #ffffffc2;
    opacity: 0;
    transform: translateY(-100%);
  }
}

@media (max-width:400px) {
  div.splash-screen div.logo {
    flex-direction: column;
  }
}