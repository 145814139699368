div.main-page {
  min-height: 100vh;
}

div.main-page div.card-box {
  backdrop-filter: blur(5px);
  background-color: #ffffffcb;
  border-radius: 15px;
  padding: 15px;
  margin: 15px auto;
  max-width: 700px;
  overflow: hidden;
  position: relative;
}

div.main-page div.card-box div.message-container {
  align-items: center;
  display: flex;
  margin-top: 10px;
}

div.main-page div.card-box {
  color: #252525;
}

div.main-page div.card-box div.message-container>img {
  max-width: 50%;
}

div.main-page div.card-box div.message-container>p {
  color: #252525;
  letter-spacing: 1.2px;
  padding-left: 10px;
}

div.main-page div.main-background-container {
  overflow: hidden;
  position: absolute;
  width: 100%;
}

div.main-page div.main-background-container img.main-background {
  image-rendering: pixelated;
  width: 100%;
}

div.main-page div.main-container {
  position: relative;
}

div.main-page div.main-container div.pet-container {
  margin: 0 auto;
  max-width: 500px;
  height: calc(100vh - 290px);
  padding-top: 50px;
}

div.main-page div.main-container div.pet-container>img {
  object-fit: contain;
  width: 100%;
}

div.main-page div.main-container div.logo-container {
  margin: 0 auto;
  margin-bottom: 70px;
  max-width: 500px;
}

div.main-page div.main-container div.logo-container div.logo {
  height: 60px;
  margin: 5px 0;
  position: relative;
}

div.main-page div.main-container div.logo-container div.logo>h1 {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

div.main-page div.main-container div.logo-container div.logo h1.cuntrat {
  color: #252525;
  opacity: 0;
}

div.main-page div.main-container div.logo-container div.logo h1.cuntrat.anim {
  animation: cuntrat-anim 0.1s ease-in-out 1.5s 1 normal forwards;
}

@keyframes cuntrat-anim {
  to {
    opacity: 1;
  }
}

div.main-page div.main-container div.logo-container div.logo h1.cuntrat span {
  display: inline-block;
}

div.main-page div.main-container div.logo-container div.logo h1.cuntrat span#s1 {
  color: #7b18d8;
}

div.main-page div.main-container div.logo-container div.logo h1.cuntrat.anim span#s1 {
  animation: cuntrat-span-s1-anim 0.25s ease-in-out 2s 1 normal forwards;
}

div.main-page div.main-container div.logo-container div.logo h1.cuntrat.anim span#s2 {
  animation: cuntrat-span-anim 0.25s ease-in-out 2.05s 1 normal forwards;
}

div.main-page div.main-container div.logo-container div.logo h1.cuntrat.anim span#s3 {
  animation: cuntrat-span-anim 0.25s ease-in-out 2.1s 1 normal forwards;
}

div.main-page div.main-container div.logo-container div.logo h1.cuntrat.anim span#s4 {
  animation: cuntrat-span-anim 0.25s ease-in-out 2.15s 1 normal forwards;
}

div.main-page div.main-container div.logo-container div.logo h1.cuntrat.anim span#s5 {
  animation: cuntrat-span-anim 0.25s ease-in-out 2.2s 1 normal forwards;
}

div.main-page div.main-container div.logo-container div.logo h1.cuntrat.anim span#s6 {
  animation: cuntrat-span-anim 0.25s ease-in-out 2.25s 1 normal forwards;
}

div.main-page div.main-container div.logo-container div.logo h1.cuntrat.anim span#s7 {
  animation: cuntrat-span-anim 0.25s ease-in-out 2.3s 1 normal forwards;
}

@keyframes cuntrat-span-anim {
  50% {
    transform: translateY(-10px);
  }

  90% {
    transform: translateY(2px);
  }

  100% {
    color: #8728e0d7;
    transform: none;
  }
}

@keyframes cuntrat-span-s1-anim {
  50% {
    transform: translateY(-10px);
  }

  90% {
    transform: translateY(2px);
  }

  100% {
    color: #252525;
    transform: none;
  }
}

div.main-page div.main-container div.logo-container div.logo h1.contract {
  opacity: 0;
}

div.main-page div.main-container div.logo-container div.logo h1.contract.anim {
  animation: contract-anim 1.5s ease-in-out 0s 1 normal forwards;
}

@keyframes contract-anim {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

div.main-page div.main-container div.logo-container div.logo span.curt {
  display: inline-block;
  height: 100%;
  position: absolute;
  right: 0px;
}

div.main-page div.main-container div.logo-container div.logo span.curt.c1 {
  background-color: #ddaaff;
}

div.main-page div.main-container div.logo-container div.logo span.curt.c2 {
  background-color: #751eaf;
}

div.main-page div.main-container div.logo-container div.logo span.curt.c1.anim {
  animation: curt-anim 1s ease-in-out 1s 1 normal forwards;
}

div.main-page div.main-container div.logo-container div.logo span.curt.c2.anim {
  animation: curt-anim 0.7s ease-in-out 1.2s 1 normal forwards;
}

@keyframes curt-anim {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
  }

  100% {
    left: 0px;
    width: 0%;
    right: auto;
  }
}

div.main-page div.main-container div.about-page-container {
  margin-top: 20px;
}

@media screen and (max-width: 800px) {
  div.main-page div.main-background-container img.main-background {
    width: 800px;
  }
}

@media screen and (max-width: 550px) {
  div.main-page div.main-container div.logo-container {
    max-width: none;
    width: calc(100% - 50px);
  }
}

@media screen and (max-width: 740px) {
  div.main-page div.main-container div.about-page-container div.card-box {
    max-width: none;
    width: calc(100vw - 40px);
  }
}