/* General style */
button.button {
  background: rgb(222, 184, 255);
  background: linear-gradient(135deg, rgba(222, 184, 255, 1) 0%, rgba(165, 95, 232, 1) 18%, rgba(120, 32, 204, 1) 48%, rgba(47, 7, 84, 1) 76%, rgba(11, 1, 19, 1) 100%);
  background-size: 150% 150%;
  border-radius: 8px;
  color: #d2c3ff;
  cursor: pointer;
  min-width: 20px;
  padding: 10px;
  transition: 0.25s;
  user-select: none;
}

button.button>* {
  vertical-align: middle;
}

button.button.align-left {
  text-align: left;
}

button.button[disabled]:not(.empty):not(.borderless) {
  background: none;
  background-color: #c2c2c2;
}

button.button:active {
  opacity: 0.7;
}

button.button:active * {
  animation: button-push 0.05s ease-out 0s 1 normal forwards;
}

button.button.rotate-img-90:active>img {
  animation: button-push-rotate-90 0s linear 0s 1 normal forwards;
}

button.button.rotate-img-180:active>img {
  animation: button-push-rotate-180 0s linear 0s 1 normal forwards;
}

button.button.rotate-img-270:active>img {
  animation: button-push-rotate-270 0s linear 0s 1 normal forwards;
}

@keyframes button-push {
  100% {
    transform: translateY(2px);
  }
}

@keyframes button-push-rotate-90 {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(90deg) translateX(2px);
  }
}

@keyframes button-push-rotate-180 {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(180deg) translateY(-2px);
  }
}

@keyframes button-push-rotate-270 {
  0% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(270deg) translateX(-2px);
  }
}

button.button.full-width {
  width: 100%;
}

button.button.tl {
  border-top-left-radius: 25px;
}

button.button.tr {
  border-top-right-radius: 25px;
}

button.button.bl {
  border-bottom-left-radius: 25px;
}

button.button.br {
  border-bottom-right-radius: 25px;
}

button.button.borderless {
  border: 0px solid black !important;
}

button.button.reduced {
  height: 30px;
  min-width: 30px;
  padding: 0px 5px 0px;
}

button.button.reduced>img {
  height: 14px;
  margin: 0px auto;
  transform: translateY(-1px);
  width: 14px;
}

button.button.reduced>p {
  font-size: 0.7rem;
  transform: translateY(-2px);
}

button.button.rotate-img-90>img {
  transform: rotate(90deg)
}

button.button.rotate-img-180>img {
  transform: rotate(180deg);
}

button.button.rotate-img-270>img {
  transform: rotate(270deg);
}

button.button>*:not(input):not(a) {
  display: inline-block;
  vertical-align: middle;
}

button.button>img {
  height: 20px;
  width: 20px;
}

button.button>p {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

button.button>img+p,
button.button>span.animated-figure+p {
  margin-left: 5px;
}

/* Complete style */
button.button.complete {
  background: rgb(140, 255, 130);
  background: linear-gradient(135deg, rgba(140, 255, 130, 1) 0%, rgba(107, 246, 75, 1) 18%, rgba(62, 201, 18, 1) 48%, rgba(22, 106, 5, 1) 76%, rgba(6, 19, 1, 1) 100%);
  background-size: 150% 150%;
}

button.button.complete:not(.empty)>img,
button.button.complete:not(.empty)>p {
  filter: hue-rotate(180deg) brightness(1.45);
}

button.button.empty.complete {
  filter: hue-rotate(180deg) brightness(2);
}

/* Error style */
button.button.error {
  background: rgb(255, 169, 169);
  background: linear-gradient(135deg, rgba(255, 169, 169, 1) 0%, rgba(255, 109, 109, 1) 18%, rgba(227, 32, 32, 1) 48%, rgba(106, 5, 5, 1) 76%, rgba(19, 1, 1, 1) 100%);
  background-size: 150% 150%;
}

button.button.error:not(.empty)>img,
button.button.error:not(.empty)>p {
  filter: hue-rotate(90deg) saturate(1.3);
}

button.button.empty.error {
  filter: hue-rotate(90deg) brightness(1.3);
}

/* Warning style */
button.button.warning {
  background: rgb(255, 248, 169);
  background: linear-gradient(135deg, rgba(255, 248, 169, 1) 0%, rgba(255, 246, 109, 1) 18%, rgba(227, 205, 32, 1) 48%, rgba(106, 88, 5, 1) 76%, rgba(19, 17, 1, 1) 100%);
  background-size: 150% 150%;
}

button.button.warning>p {
  filter: hue-rotate(150deg) brightness(0.3);
}

button.button.warning:hover>p {
  filter: brightness(1.5);
}

button.button.warning:not(.empty)>img {
  filter: hue-rotate(150deg);
}

button.button.empty.warning {
  filter: hue-rotate(150deg) brightness(2.6);
}

button.button.empty.warning>p {
  filter: none;
}

/* Glass style */
button.button.empty.glass {
  filter: saturate(0) brightness(5);
}

button.button.empty.glass:hover {
  background-color: #ffffff9c;
  filter: saturate(0);
}

button.button.empty.dark-glass {
  filter: saturate(0) brightness(0);
}

button.button.empty.dark-glass:hover {
  background-color: #000000c0;
  filter: saturate(0);
}

button.button.empty.dark-glass:hover>* {
  color: #fff !important;
}

/* White stone style */
button.button.empty.white-stone {
  background-color: rgba(255, 255, 255, 0);
}

button.button.empty.white-stone>* {
  filter: saturate(0) brightness(0.7);
}

button.button.empty.white-stone:hover {
  background-color: #fff;
}

button.button.empty.white-stone:hover>* {
  filter: brightness(0);
}

button.button>img {
  filter: brightness(0.8);
}

/* Empty style */
button.button.empty {
  background: transparent;
  border: 1px solid rgb(119, 37, 196);
  color: rgb(119, 37, 196);
}

/* Hover style and animation */
button.button:hover {
  background-position-x: 99%;
  color: #fff;
}

button.button.empty:hover {
  background-color: rgb(119, 37, 196);
  background-position-x: 0%;
  border: 1px solid rgb(217, 160, 255);
}

button.button.empty[disabled]:hover {
  background-color: transparent !important;
}

button.button:hover>img {
  filter: brightness(10);
}

button.button.animate:hover>img {
  animation: button-hover 1.5s linear 0s infinite normal forwards;
}

@keyframes button-hover {
  25% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(10deg);
  }

  35% {
    transform: rotate(-20deg);
  }

  40% {
    transform: rotate(20deg);
  }

  45% {
    transform: rotate(-20deg);
  }

  50% {
    transform: translate(20deg);
  }

  55% {
    transform: rotate(0deg);
  }
}