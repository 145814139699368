div.bank-card-editor div.popup {
  max-width: 600px;
}

div.bank-card-editor div.popup div.content {
  display: flex;
}

div.bank-card-editor div.popup div.content div.bank-card-container {
  width: 300px;
}

div.bank-card-editor div.bank-card-container div.bank-card {
  background: rgb(51, 51, 51);
  background: linear-gradient(36deg, rgba(51, 51, 51, 1) 0%, rgba(73, 73, 73, 1) 40%, rgba(104, 104, 104, 1) 93%, rgba(139, 139, 139, 1) 100%);
  border-radius: 10px;
  height: 170px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  width: 250px;
}

div.bank-card-editor div.bank-card span.background {
  animation: bank-card-background 0.5s ease-in-out 0s 1 normal none;
  left: 0px;
  height: 100%;
  width: 100%;
}

@keyframes bank-card-background {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

div.bank-card-editor div.bank-card span.background.visa {
  background: rgb(15, 46, 154);
  background: linear-gradient(36deg, rgba(15, 46, 154, 1) 0%, rgba(22, 54, 115, 1) 40%, rgba(44, 73, 255, 1) 93%, rgba(90, 102, 255, 1) 100%);
  transition: backgroud-color 1s linear;
}

div.bank-card-editor div.bank-card span.background.mastercard {
  background: rgb(194, 24, 24);
  background: linear-gradient(0deg, rgba(194, 24, 24, 1) 0%, rgba(187, 22, 22, 1) 41%, rgba(250, 47, 47, 1) 100%);
}

div.bank-card-editor div.bank-card img.card-logo {
  animation: bank-card-logo 0.5s ease-in-out 0.1s 1 normal forwards;
  filter: brightness(2) grayscale();
  left: 50px;
  opacity: 0;
  top: 30px;
  width: 300px;
}

@keyframes bank-card-logo {
  from {
    transform: translateX(10px);
  }

  to {
    opacity: 0.3;
  }
}

div.bank-card-editor div.bank-card.wait {
  background: none;
  background-color: #bebebe;
  position: initial;
}

div.bank-card-editor div.bank-card.wait>span.wait-curtain {
  animation: bank-card-wait 1s linear 0s infinite normal none;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 10%, rgba(205, 205, 205, 1) 50%, rgba(255, 255, 255, 0) 90%);
  display: block;
  height: 100%;
  position: initial;
  width: 100%;
}

@keyframes bank-card-wait {
  0% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

div.bank-card-editor div.bank-card>* {
  position: absolute;
}

div.bank-card-editor div.bank-card img.page-logo-icon {
  filter: grayscale() brightness(2);
  left: 5px;
  opacity: 0.5;
  top: 5px;
  width: 25px;
}

div.bank-card-editor div.bank-card span.logo {
  color: #ffffff77;
  font-size: 0.9rem;
  font-weight: bold;
  left: 35px;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  top: 9px;
}

div.bank-card-editor div.bank-card span.card-number {
  color: #ffffffc4;
  font-size: 1.35rem;
  left: 0px;
  top: 50px;
  width: 100%;
}

div.bank-card-editor div.bank-card span.card-code {
  bottom: 15px;
  color: #ffffff96;
  font-size: 0.65rem;
  font-weight: bold;
  left: calc(100% - 67px);
  text-transform: uppercase;
}

div.bank-card-editor div.bank-card span.card-exp {
  bottom: 15px;
  color: #ffffff96;
  font-size: 0.65rem;
  font-weight: bold;
  left: 110px;
  text-transform: uppercase;
}

div.bank-card-editor div.bank-card span.logo::before {
  content: 'Cuntrat';
}

div.bank-card-editor div.form-container {
  padding: 10px;
  position: relative;
  width: 100%;
}

@media (max-width: 590px) {
  div.bank-card-editor div.popup div.content {
    display: initial;
  }

  div.bank-card-editor div.popup div.content div.bank-card-container {
    width: 100%;
  }

  div.bank-card-editor div.form-container {
    width: calc(100% - 20px);
  }
}

@media (max-width: 610px) {
  div.bank-card-editor div.popup {
    max-width: none;
    width: calc(100% - 30px);
  }
}