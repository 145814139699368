/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');

/* RESET */
* {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  margin: 0;
  padding: 0px;
  font-family: 'Varela', sans-serif;
}

/* Body general scroll bar */
body::-webkit-scrollbar {
  display: none;
}

/* *** SROLLBAR *** */
/* Width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #727272;
}

.full-width {
  width: 100%;
}

/* Exponential numeric text */
span.exp-text {
  font-size: 0.6rem;
  vertical-align: top;
}

header a,
nav a {
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 1.1rem;
}

/* Body */
body {
  background-color: #f5f5f5;
}

div.App {
  background-color: #f5f5f5;
}

div.App>div.container {
  padding-top: 75px;
}

input:not([type="submit"]):not([type="button"]):not(.inputbar),
select:not(.selectbar) {
  background-color: #fafafa;
  border: 2px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  color: rgb(59, 59, 59);
  font-size: 0.9rem;
  margin: 5px;
  padding: 10px 15px 10px;
  outline: none;
  transition: 0.25s;
}

a {
  text-decoration: underline;
}

input:not(.inputbar).uppercase {
  text-transform: uppercase;
}

input:not(.inputbar).lowercase {
  text-transform: lowercase;
}

input:not(.inputbar).capitalize {
  text-transform: capitalize;
}

input:not([type="submit"]):not([type="button"]):not(.inputbar).error,
select:not(.selectbar).error {
  animation: input-error-shake 0.3s ease-in-out 0s 1 normal none;
  background-color: rgb(255, 234, 234);
  border-color: rgb(255, 45, 45);
  color: rgb(255, 45, 45);
}

@keyframes input-error-shake {
  10% {
    transform: translateX(1px);
  }

  25% {
    transform: translateX(-2px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-2px);
  }

  90% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0px);
  }
}

input:not([type="submit"]):not([type="button"]):not(.inputbar):focus,
select:not(.selectbar):focus {
  border: 2px solid #7b18d8;
  background-color: #fff;
  color: rgb(80, 15, 141);
}

input.error:not([type="submit"]):not([type="button"]):not(:focus):not([disabled]):not(.inputbar)::placeholder {
  color: #df5959;
}

input:not([type="submit"]):not([type="button"]):not(.inputbar)::placeholder {
  text-transform: none;
}

span.required {
  color: rgb(233, 0, 0);
  margin-right: 2px;
}

span.required::after {
  content: '*';
}

/* Popup */
div.popup {
  animation: popup-appear 0.25s ease-in-out 0s 1 normal forwards;
  background: #fff;
  border-radius: 15px;
  margin: auto;
  max-width: 350px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes popup-appear {
  0% {
    transform: translateY(-110vh);
  }

  55% {
    transform: translateY(-40%);
  }

  60% {
    transform: translateY(-35%);
  }

  100% {
    transform: translateY(-50%);
  }
}

.popup-wrapper {
  animation: popup-wrapper-appear 0.25s ease-out 0s 1 normal forwards;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

.popup-wrapper[hide] {
  animation: popup-wrapper-hide 0.25s ease-in 0.5s 1 normal forwards;
  display: block !important;
}

.popup-wrapper[hide]>div {
  animation: popup-hide 0.25s ease-in 0s 1 normal forwards;
}

@keyframes popup-hide {
  25% {
    transform: translateY(-40%);
  }

  40% {
    transform: translateY(-40%);
  }

  100% {
    transform: translateY(-150vh);
  }
}

@keyframes popup-wrapper-appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes popup-wrapper-hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

div.popup div.top-bar {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  min-height: 35px;
}

div.popup div.top-bar .title {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

div.popup div.top-bar>* {
  margin: auto 1px;
}

[hide] {
  display: none;
}

.highlight {
  color: #7b18d8;
}

.overset {
  color: #666666;
}

.example {
  color: #936eb3;
}

/* Pills */
span.pill {
  border-radius: 10px;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: bold;
  margin: 2px;
  padding: 5px 10px 5px;
  text-align: center;
}

span.pill.complex {
  background: rgb(255, 133, 10);
  background: linear-gradient(135deg, rgba(255, 133, 10, 1) 0%, rgba(255, 220, 75, 1) 56%, rgba(255, 48, 48, 1) 100%);
  color: rgb(107, 78, 24);
}

span.pill.complex::before {
  content: "Subdivisiones";
}

span.pill.inactive {
  background-color: #aaaaaa;
  color: rgb(90, 90, 90);
}

span.pill.inactive::before {
  content: "Inactiva";
}

span.pill.lease {
  background: rgb(226, 203, 255);
  background: linear-gradient(130deg, rgba(226, 203, 255, 1) 0%, rgba(102, 65, 204, 1) 51%, rgba(222, 85, 255, 1) 100%);
  color: rgb(249, 243, 255);
}

span.pill.lease::before {
  content: "En renta";
}

span.pill.on-lease {
  border: 1px solid rgb(176, 40, 255);
  color: rgb(126, 0, 199);
}

span.pill.on-lease::before {
  content: "Rentando...";
}

span.pill.sale {
  background: rgb(224, 255, 203);
  background: linear-gradient(130deg, rgb(232, 255, 203) 0%, rgba(75, 204, 65, 1) 51%, rgba(85, 255, 187, 1) 100%);
  color: rgb(247, 255, 245);
}

span.pill.sale::before {
  content: "En venta";
}

span.pill.sold {
  border: 1px solid rgb(126, 126, 126);
  color: rgb(87, 87, 87)
}

span.pill.sold::before {
  content: "Vendida";
}

span.pill.suspended {
  border: 1px solid rgb(255, 100, 100);
  color: rgb(255, 59, 59);
}

span.pill.suspended:before {
  content: "Suspendida";
}

span.pill.template {
  border: 1px solid rgb(26, 26, 26);
  color: rgb(46, 46, 46);
}

span.pill.template::before {
  content: "Plantilla";
}

span.pill.status {
  color: #fff;
  font-size: 0.7rem;
  margin-right: 3px;
  padding: 5px 8px 5px;
}

span.pill.cancelled {
  background-color: #cecece;
}

span.pill.cancelled::before {
  color: #8a8a8a;
  content: "Cancelado";
}

span.pill.finalized::before {
  color: #866a9c;
  content: "Finalizado";
}

span.pill.active {
  background: rgb(45, 219, 40);
  background: linear-gradient(45deg, rgba(45, 219, 40, 1) 1%, rgba(64, 255, 57, 1) 52%, rgba(153, 255, 150, 1) 100%);
}

span.pill.active::before {
  content: "Al corriente";
}

span.pill.cut-near {
  background: rgb(247, 90, 10);
  background: linear-gradient(25deg, rgba(247, 90, 10, 1) 1%, rgba(255, 166, 57, 1) 52%, rgba(255, 178, 100, 1) 100%);
}

span.pill.cut-near::before {
  content: "Pronto corte";
}

span.pill.cut-today {
  background: rgb(247, 45, 10);
  background: linear-gradient(67deg, rgba(247, 45, 10, 1) 0%, rgba(255, 105, 24, 1) 30%, rgba(255, 141, 25, 1) 51%, rgba(254, 102, 24, 1) 73%, rgba(228, 13, 13, 1) 100%);
}

span.pill.cut-today::before {
  content: "Corte hoy";
}

span.pill.delayed {
  background: rgb(190, 19, 19);
  background: linear-gradient(326deg, rgba(190, 19, 19, 1) 0%, rgba(247, 10, 10, 1) 12%, rgba(255, 57, 57, 1) 61%, rgba(255, 100, 100, 1) 94%);
}

span.pill.delayed::before {
  content: "Atraso";
}

[disabled]:not(div.popup-wrapper) {
  border-color: #a7a7a7 !important;
  color: rgb(122, 122, 122) !important;
  cursor: not-allowed !important;
  filter: grayscale() !important;
  pointer-events: none !important;
  transition: 0.25s !important;
}

/* Responsive */
@media(max-width: 480px) {
  div.popup {
    max-width: 85%;
  }
}

@media(max-width: 740px) {
  div.flex-box.wrap {
    flex-wrap: wrap;
    justify-content: start;
  }

  span.conditional {
    opacity: 0;
    position: absolute;
  }
}

div.flex-box {
  display: flex;
  justify-content: space-evenly;
  white-space: nowrap;
  width: 100%;
}

div.flex-box.shadow-box {
  box-shadow: 0px 0px 3px 1px #0000003f;
}

span.arrow {
  height: 0px;
  margin: 0px 5px;
  transform: rotate(45deg);
  width: 0px;
  border: 7px solid #7b18d8;
  border-bottom: 7px solid transparent;
  border-left: 7px solid transparent;
}

span.arrow.previous {
  border-top: 7px solid rgb(114, 114, 114);
  border-right: 7px solid rgb(114, 114, 114);
  margin-right: 10px;
}

div.flex-box div.child {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

div.flex-box div.child.text-wrap {
  text-wrap: wrap;
}

div.flex-box.m3>*,
div.flex-box div.child.m3>* {
  margin: 3px;
}

div.flex-box.m5>*,
div.flex-box div.child.m5>* {
  margin: 5px;
}

div.flex-box.p5,
div.flex-box div.child.p5 {
  padding: 5px;
  width: calc(100% - 10px);
}

div.flex-box.p10,
div.flex-box div.child.p10 {
  padding: 10px;
  width: calc(100% - 20px);
}

div.flex-box.jc-center,
div.flex-box div.child.jc-center {
  justify-content: center;
}

div.flex-box.jc-left,
div.flex-box div.child.jc-left {
  justify-content: left;
}

div.flex-box.jc-right,
div.flex-box div.child.jc-right {
  justify-content: right;
}

div.flex-box.dir-column,
div.flex-box div.child.dir-column {
  flex-direction: column;
}

div.flex-box.al-start,
div.flex-box div.child.al-start {
  align-items: start;
}

div.flex-box.al-end,
div.flex-box div.child.al-end {
  align-items: end;
}

div.flex-box.jc-spacebtw,
div.flex-box div.child.jc-spacebtw {
  justify-content: space-between;
}

div.flex-box div.child.no-flex {
  display: block;
  padding: 5px;
}

div.flex-box div.child.auto-width {
  width: auto;
}

div.flex-box div.box,
div.flex-box div.child div.box {
  overflow-y: hidden;
  width: 100%;
}

img.icon {
  height: 25px;
  margin-right: 3px;
  width: 25px;
}

.hyperlink {
  border-radius: 8px;
  color: #7b18d8;
  cursor: pointer;
  margin: 0px 2px;
  padding: 5px;
  transition: 0.25s;
  user-select: none;
}

.hyperlink.invert {
  color: rgb(241, 226, 255);
}

.hyperlink:hover {
  color: rgb(241, 226, 255);
  background-color: #7b18d8;
}

.hyperlink.invert:hover {
  color: #7b18d8;
  background-color: #fff;
}

@keyframes popup-wrapper {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes popup-wrapper-hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

div.box {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(181, 151, 201);
  border-radius: 8px;
  box-shadow: 0px 0px 2px 2px rgba(59, 59, 59, 0.1);
  margin: 5px 2px;
  overflow-x: hidden;
  padding: 10px;
}

div.box.hide-y {
  overflow-y: hidden;
}

div.box.borderless {
  border: 0px
}

div.box.shadowless {
  box-shadow: none;
}

div.empty-box {
  display: block;
  text-align: center;
}

div.preview {
  max-width: 90%;
}

/* Loagind figures */
div.load-figures {
  margin: auto 0px;
  text-align: center;
}

div.load-figures>* {
  margin: 20px;
}

span.animated-figure {
  border: 20px solid white;
  display: inline-block;
  vertical-align: middle;
}

span.animated-figure.f1 {
  animation: animated-figure-f1 2.5s ease-in-out 0s infinite;
  border-radius: 20%;
}

span.animated-figure.f2 {
  animation: animated-figure-f2 0.7s linear 0s infinite;
  border-radius: 20%;
}

span.animated-figure.f3 {
  animation: animated-figure-f3 3s cubic-bezier(.56, -0.01, .99, .15) 0s infinite;
  border-radius: 100%;
  border-left-color: transparent;
  border-width: 17px;
  height: 10px;
  margin: 18px;
  width: 10px;
}

span.animated-figure.mini {
  border-width: 8px;
  margin: 2px;
}

span.animated-figure.f1.mini {
  animation: animated-figure-f1 2s ease-in-out 0s infinite;
}

span.animated-figure.f2.mini {
  animation: animated-figure-f2 0.5s linear 0s infinite;
}

span.animated-figure.f3.mini {
  animation: animated-figure-f3 1.7s cubic-bezier(.56, -0.01, .99, .15) 0s infinite;
  border-width: 6px;
  height: 4px;
  width: 4px;
}

span.animated-figure.black {
  border-color: black;
}

span.animated-figure.gray {
  border-color: #5e5e5e;
}

span.animated-figure.purple {
  border-color: #7b18d8;
}

span.animated-figure.f3.black,
span.animated-figure.f3.gray,
span.animated-figure.f3.purple {
  border-left-color: transparent;
}

img.img-icon {
  border-radius: 8px;
  width: 27px;
}

/* Common keyframes */
@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes animated-figure-f1 {
  20% {
    border-radius: 20%;
  }

  30% {
    border-radius: 100%;
    transform: rotate(0deg);
  }

  35% {
    border-radius: 100%;
    transform: rotate(45deg);
  }

  45% {
    border-radius: 20%;
  }

  70% {
    border-radius: 20%;
  }

  80% {
    border-radius: 100%;
    transform: rotate(45deg);
  }

  85% {
    border-radius: 100%;
    transform: rotate(0deg);
  }

  95% {
    border-radius: 20%;
  }
}

@keyframes animated-figure-f2 {
  15% {
    border-bottom-color: transparent;
  }

  30% {
    border-left-color: transparent;
  }

  45% {
    border-top-color: transparent;
  }

  60% {
    border-right-color: transparent;
  }
}

@keyframes animated-figure-f3 {
  10% {
    transform: rotate(-45deg);
  }

  25% {
    transform: rotate(45deg);
  }

  27% {
    transform: rotate(40deg);
  }

  29% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(130deg);
  }

  53% {
    transform: rotate(125deg);
  }

  55% {
    transform: rotate(130deg);
  }

  75% {
    transform: rotate(225deg);
  }

  77% {
    transform: rotate(220deg);
  }

  78% {
    transform: rotate(225deg);
  }

  100% {
    transform: rotate(365deg);
  }
}